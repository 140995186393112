<template>
  <div>
    <b-table
        hover
        :items="getUserDataTable"
        :fields="fields"
        ref="user_table"
        :per-page="userList.perPage"
        class="table-bordered table-hover"
        show-empty>
      <template v-slot:empty="scope">
        <h6 class="text-center">Пользователи отсутствуют</h6>
      </template>
      <template v-slot:cell(index)="row">
        {{ userList.perPage * (userList.currentPage - 1) + row.index + 1 }}
      </template>
      <template v-slot:cell(id)="row">
        {{ (row.item.id) }}
      </template>
      <template v-slot:cell(interaction)="row">
        <b-button class="" variant="primary"
                  :title="isAdmin ? 'Редактирование' : 'Просмотр'"
                  @click="prepareDetailPage(row.item)"
        >
          <b-icon-pencil v-if="isAdmin" font-scale="0.75"></b-icon-pencil>
          <b-icon-eye v-else font-scale="0.75"></b-icon-eye>
        </b-button>
        <b-button v-if="isAdmin" class="ml-1" variant="primary"
                  :title="'Получить токен'"
                  @click="prepareTokenPage(row.item)"
        >
          <b-icon-key font-scale="0.75"></b-icon-key>
        </b-button>
        <b-button v-if="isAdmin" class="ml-1" variant="primary"
                  title="Получить токен"
                  @click="prepareBonusModal(row.item, false)"
        >
          <b-icon-plus font-scale="0.75"></b-icon-plus>
        </b-button>
        <b-button v-if="isAdmin" class="ml-1" variant="primary"
                  title="Списать бонусы"
                  @click="prepareBonusModal(row.item, true)"
        >
          <b-icon-dash font-scale="0.75"></b-icon-dash>
        </b-button>
      </template>
    </b-table>
    <div class="d-flex">
      <div class="col-md-2 pl-0">
        <b-form-select
            v-model="userList.perPage"
            class="text-light border-secondary bg-secondary"
        >
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
        </b-form-select>
      </div>
      <span class="align-self-center">записей на страницу</span>
      <b-pagination
          v-model="userList.currentPage"
          class="my-0 ml-auto"
          :total-rows="userList.totalCount"
          :per-page="userList.perPage"
          aria-controls="template-table"
          @input="getUserDataTable"
      ></b-pagination>
    </div>
    <b-modal ref="bonus-modal" hide-footer>
      <bonus-adder ref="bonus-adder" @bonus-updated="bonusAdded" @bonus-not-updated="bonusNotAdded" @bonus-modal-close="bonusModalClose" :reduceBonus="reduceBonusModal"
                   :userId="selectedUserId"
                   :showSubmit="true"/>
    </b-modal>
    <b-modal ref="bonus-modal-success" title="Успех" ok-only ok-title="Ok" button-size="sm">
      Изменение бонусов прошло успешно
    </b-modal>
    <b-modal ref="bonus-modal-error" title="Ошибка" ok-only ok-title="Ok" button-size="sm">
      Произошла ошибка при изменении бонусов
    </b-modal>
  </div>
</template>
<script>
import users from '@/modules/users';
import BonusAddition from './BonusAddition.vue';
import {BIcon, BIconPencil, BIconPlus, BIconDash} from 'bootstrap-vue';
import {mapGetters} from "vuex";

export default {
  name: 'user-list',
  components: {
    'bonus-adder': BonusAddition,
    'b-icon-pencil': BIconPencil,
    'b-icon-plus': BIconPlus,
    'b-icon-dash': BIconDash
  },
  data() {
    return {
      fields: [
        {
          key: 'index', label: '№'
        },
        {
          key: 'id', label: 'ID'
        },
        {
          key: 'bonusAmount', label: 'Бонусы'
        },
        {
          key: 'interaction', label: 'Взаимодействие'
        }
      ],
      userList: {
        totalCount: 0,
        perPage: 20,
        currentPage: 1
      },
      reduceBonusModal: true,
      selectedUserId: null
    };
  },
  computed: {
    ...mapGetters([
        "isAdmin"
    ])
  },
  methods: {
    prepareBonusModal(userData, reduceBonus) {
      this.selectedUserId = userData.id;
      this.reduceBonusModal = reduceBonus;
      this.$refs['bonus-modal'].show();
    },
    bonusAdded() {
      this.$refs['bonus-modal'].hide();
      this.selectedUserId = null;
      this.$refs['user_table'].refresh();
      this.$refs['bonus-modal-success'].show();
    },
    bonusNotAdded() {
      this.$refs['bonus-modal'].hide();
      this.selectedUserId = null;
      this.$refs['bonus-modal-error'].show();
    },
    bonusModalClose() {
      this.$refs['bonus-modal'].hide();
      this.selectedUserId = null;
    },
    getUserDataTable(context) {
      return users.getUserList((context.currentPage - 1) * context.perPage,
          context.perPage).then(resp => {
        this.userList.totalCount = resp.data.totalCount;
        return resp.data.results;
      });
    },
    prepareDetailPage(user) {
      this.$router.push(`/user/${user.id}`);
    },
    prepareTokenPage(user) {
      this.$router.push(`/userToken/${user.id}`);
    }
  }
}
</script>
